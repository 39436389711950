/* eslint-disable no-plusplus */
import humps from 'humps'
import dateFormat from 'date-fns/format'
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'

import {
  PHONE_NUMBER_REGEX,
  EMAIL_REGEX,
  BANK_ACCOUNT_FIELDS,
  BANK_ACCOUNT_TYPE_OPTIONS,
  KEY_PIX_OPTIONS,
} from '_utils/constants'

export const createFormData = (data, shouldDecamelize = false) => {
  const formData = new FormData()

  Object.keys(data).forEach(field => {
    const fieldValue = data[field]
    const formDataValue = (() => {
      if (!fieldValue) {
        return ''
      }
      if (fieldValue instanceof Blob || typeof fieldValue !== 'object') {
        return fieldValue
      }

      return JSON.stringify(fieldValue)
    })()

    formData.append(shouldDecamelize ? humps.decamelize(field) : field, formDataValue)
  })

  return formData
}

export const createFormDataDocuments = (data, isCamelized = false) => {
  const formData = new FormData()
  Object.keys(data).forEach(field => {
    const fieldValue = data[field]

    const hasFile = Array.isArray(fieldValue)
      ? fieldValue?.find(value => value instanceof Blob)
      : false
    const formDataValue = (() => {
      if (!fieldValue && typeof fieldValue !== 'boolean') {
        return ''
      }
      if (
        fieldValue instanceof Blob ||
        typeof fieldValue !== 'object' ||
        hasFile ||
        Array.isArray(fieldValue)
      ) {
        return fieldValue
      }

      return JSON.stringify(fieldValue)
    })()

    if (Array.isArray(formDataValue)) {
      formDataValue.forEach(value => {
        if (value instanceof Blob && value.constructor === Blob) {
          formData.append(isCamelized ? field : humps.decamelize(field), value, value.name)
        } else {
          formData.append(isCamelized ? field : humps.decamelize(field), value)
        }
      })
    } else if (formDataValue instanceof Blob && formDataValue.constructor === Blob) {
      formData.append(
        isCamelized ? field : humps.decamelize(field),
        formDataValue,
        formDataValue.name
      )
    } else {
      formData.append(isCamelized ? field : humps.decamelize(field), formDataValue)
    }
  })

  return formData
}

export const formatFormData = (data, isCamelized = false) => {
  const formData = new FormData()
  Object.keys(data).forEach(field => {
    const fieldValue = data[field]

    if (fieldValue === null || fieldValue === undefined) {
      return
    }

    const hasFile = Array.isArray(fieldValue)
      ? fieldValue?.find(value => value instanceof Blob)
      : false
    const formDataValue = (() => {
      if (
        fieldValue instanceof Blob ||
        typeof fieldValue !== 'object' ||
        hasFile ||
        Array.isArray(fieldValue)
      ) {
        return fieldValue
      }

      // Convertendo todos os outros tipos para string
      return String(fieldValue)
    })()

    if (Array.isArray(formDataValue)) {
      formDataValue.forEach(value => {
        if (value instanceof Blob && value.constructor === Blob) {
          formData.append(isCamelized ? field : humps.decamelize(field), value, value.name)
        } else {
          formData.append(isCamelized ? field : humps.decamelize(field), value)
        }
      })
    } else if (formDataValue instanceof Blob && formDataValue.constructor === Blob) {
      formData.append(
        isCamelized ? field : humps.decamelize(field),
        formDataValue,
        formDataValue.name
      )
    } else {
      formData.append(isCamelized ? field : humps.decamelize(field), formDataValue)
    }
  })

  return formData
}

export const stringifyBoolean = value => (typeof value === 'boolean' ? value.toString() : 'false')

export const stringifyNumber = value => (typeof value === 'number' ? value.toString() : '0')

export const parseToNumber = string => (string ? string.replace(/[^+0-9]+/g, '') : '')

export const validationPhoneNumber = phone => {
  const phoneNumber = parseToNumber(phone)
  if (!!phoneNumber && phoneNumber.length === 0) {
    return false
  }
  return !PHONE_NUMBER_REGEX.test(phoneNumber)
}

export const validationEmail = email => {
  if (!email) {
    return false
  }

  return EMAIL_REGEX.test(email)
}

export const validationCep = cep => {
  const validationCepRegex = /^[0-9]{8}$/
  return !validationCepRegex.test(parseToNumber(cep))
}

// https://gist.github.com/alexbruno/6623b5afa847f891de9cb6f704d86d02
export const validationCNPJ = value => {
  if (!value) return false

  // Accept string or number
  const validTypes = typeof value === 'string' || Number.isInteger(value)

  // Remove invalid options
  if (!validTypes) return false

  // Array with all number
  const numbers = value.toString().match(/\d/g).map(Number)

  // Valid numbers of digits
  if (numbers.length !== 14) return false

  // Remove values with only equals number
  const items = [...new Set(numbers)]
  if (items.length === 1) return false

  // Validation
  const calc = x => {
    const slice = numbers.slice(0, x)
    let factor = x - 7
    let sum = 0

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i]
      sum += n * factor--
      if (factor < 2) factor = 9
    }

    const result = 11 - (sum % 11)

    return result > 9 ? 0 : result
  }

  // Separate the last 2 numbers
  const digits = numbers.slice(12)

  // Validate first number
  const digit0 = calc(12)
  if (digit0 !== digits[0]) return false

  // Validate second number
  const digit1 = calc(13)
  return digit1 === digits[1]
}

export const validationCPF = inputCPF => {
  const cpf = parseToNumber(inputCPF)
  let sum = 0
  let rest

  if (cpf === '00000000000') {
    return false
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false
  }

  sum = 0
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(cpf.substring(10, 11), 10)) {
    return false
  }

  return true
}

export const cpfCnpjMask = value => {
  const number = parseToNumber(value)
  if (number.length === 14) {
    return number.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5')
  }
  if (number.length === 11) {
    return number.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
  }

  return number
}

export const notEmptyPayload = payload => {
  const undefinedValues = [undefined, null, [], '', 0]
  const newPayload = Object.keys(payload).reduce(
    (acc, key) =>
      undefinedValues.includes(payload[key]) ||
      (Array.isArray(payload[key]) && payload[key].length === 0)
        ? acc
        : { ...acc, [key]: payload[key] },
    {}
  )
  return newPayload
}

const toTitleCase = phrase =>
  phrase
    ? phrase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : ''

export const getPropertyAddress = address => `${address ? toTitleCase(address) : ''}`

export const getPage = query =>
  query && (query.includes('?page=') || query.includes('&page='))
    ? Number(query.match(/[?&]page=([^&#]*)/)[1])
    : undefined

export const validationDate = date => {
  return date?.replace(/[^0-9]+/g, '').length && isValid(parse(date, 'dd/MM/yyyy', new Date()))
}

export const parseNumber = cpf => (cpf ? cpf.replace(/[^0-9]+/g, '') : '')

export const phoneNumberMask = value => {
  const number = parseNumber(value)
  if (number.length === 13) {
    return number.replace(/^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4}).*/, '+$1 ($2) $3 $4-$5')
  }
  if (number.length === 12) {
    return number.replace(/^(\d{2})(\d{2})(\d{4})(\d{4}).*/, '+$1 ($2) $3-$4')
  }
  if (number.length === 11) {
    return number.replace(/^(\d{2})(\d{1})(\d{4})(\d{4}).*/, '+55 ($1) $2 $3-$4')
  }
  if (number.length === 10) {
    return number.replace(/^(\d{2})(\d{4})(\d{4}).*/, '+55 ($1) $2-$3')
  }

  return number
}

export function numbersValid(value) {
  const hasElevenDigitsAndNineAfterDDD =
    value && value?.replace(/[^\d]/g, '').length === 11 && value?.replace(/[^\d]/g, '')[2] === '9'
  const hasThirteenDigitsAndNineAfterDDD =
    value && value?.replace(/[^\d]/g, '').length === 13 && value?.replace(/[^\d]/g, '')[4] === '9'
  return hasElevenDigitsAndNineAfterDDD || hasThirteenDigitsAndNineAfterDDD
}

export function formatPhoneValueMask(value) {
  const digits = value?.replace(/[^\d]/g, '')

  if (digits.length === 0) {
    return ''
  }

  if (digits?.length <= 3) {
    return `${digits?.slice(0, 3)}`
  }

  if (digits?.length <= 7) {
    return `(${digits?.slice(0, 2)}) ${digits?.slice(2, 3)} ${digits?.slice(3, 7)}`
  }

  return `(${digits?.slice(0, 2)}) ${digits?.slice(2, 3)} ${digits?.slice(3, 7)}-${digits?.slice(
    7,
    11
  )}`
}

export const phoneNumberMaskServiceOrder = value => {
  const number = parseNumber(value)
  if (number.length === 13) {
    return number.replace(/^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4}).*/, '($2) $3 $4-$5')
  }
  if (number.length === 12) {
    return number.replace(/^(\d{2})(\d{2})(\d{4})(\d{4}).*/, '($2) $3-$4')
  }
  return number
}

export const formatDate = date => {
  let time = date

  if (typeof date !== 'object') {
    // IF DATE COMES AS A STRING, IT'S CONVERTED INTERNALLY BY THIS FUNCTION
    time = new Date(date)
  }

  return dateFormat(time, "dd/MM/yyyy' às ' HH:mm")
}

export const toSlug = str => {
  const strTrim = str.replace(/^\s+|\s+$/g, '') // trim
  const strLowerCase = strTrim.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáäâãāèéëêìíïîòóöôõùúüûñç·/-,:;'
  const to = 'aaaaaaeeeeiiiiooooouuuunc______'
  // eslint-disable-next-line
  for (let i = 0, l = from.length; i < l; i++) {
    strLowerCase.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  strLowerCase
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '_') // collapse whitespace and replace by -
    .replace(/_+/g, '_') // collapse dashes
    .replace(/_+/g, '_')

  return strLowerCase
}

export const formatInputNumber = (e, min, max) => {
  if (!e.target.value.match(/^[0-9]+$/)) {
    e.target.value = null
  }

  if (Number(e.target.value) < min) {
    e.target.value = min
  }

  if (Number(e.target.value) > max) {
    e.target.value = max
  }

  e.target.value = parseInt(e.target.value, 10) || 0

  return e.target.value
}

export const formatAmount = amount => {
  const current = Number(amount).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
  return current.replace('R$', '')
}

export const formatDateDays = (date, initialState = 'yyyy-MM-dd', formatStr = 'dd/MM/yyyy') => {
  const currentDate = typeof date === 'string' ? parse(date, initialState, new Date()) : date
  if (isValid(currentDate)) {
    return dateFormat(currentDate, formatStr)
  }
  return null
}

// Format hh:mm:ss to Date
export const formatTimeToDate = time => {
  const [hours, minutes, seconds] = time.split(':')

  return new Date(null, null, null, hours, minutes, seconds)
}

export const reducerBankAccount = values => {
  const newValues = Object.entries(values).reduce((acc, [key, value]) => {
    if (key === BANK_ACCOUNT_FIELDS.BANK && value !== null && value !== undefined) {
      return {
        ...acc,
        [key]: value.id,
      }
    }
    if (key === BANK_ACCOUNT_FIELDS.BANK_ACCOUNT_TYPE && value !== null && value !== undefined) {
      return {
        ...acc,
        [key]: BANK_ACCOUNT_TYPE_OPTIONS.find(item => item.value === value)?.value || '',
      }
    }
    if (key === BANK_ACCOUNT_FIELDS.PIX_KEY_CHOICE && value !== null && value !== undefined) {
      return {
        ...acc,
        [key]: KEY_PIX_OPTIONS.find(item => item.value === value)?.value || '',
      }
    }
    if (value === null || value === undefined) {
      return { ...acc, [key]: '' }
    }
    return { ...acc, [key]: value }
  }, {})
  return newValues
}

export const removeDuplicatedObjectFromArray = (array, key) => {
  return array.filter((value, index, self) => index === self.findIndex(t => t[key] === value[key]))
}

export const formatCurrency = value =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)

export const convertCurrencyValueToNumber = value => {
  const stringifiedValue = String(value).replace('R$', '')

  if (stringifiedValue.length > 6) {
    return Number(stringifiedValue.replace(/\./g, '').replace(',', '')) / 100
  }

  if (stringifiedValue) {
    return Number(stringifiedValue.replace(',', '.'))
  }

  return null
}

export const formatAddress = (
  property,
  useLineBreak = false,
  useCep = false,
  useComplement = false
) => {
  const allKeys = ['number', 'complement', 'cep', 'neighborhood', 'city', 'uf']
  const conditionalKeys = ['complement', 'cep']
  const address = property.get('address')

  const filteredKeys = allKeys.reduce((acc, currentKey) => {
    if (!conditionalKeys.includes(currentKey)) {
      return [...acc, currentKey]
    }

    const showComplement = useComplement && property.get('complement')
    const showCEP = useCep && property.get('cep')

    if ((currentKey === 'cep' && showCEP) || (currentKey === 'complement' && showComplement)) {
      return [...acc, currentKey]
    }
    return acc
  }, [])

  const fullAddress = filteredKeys.reduce((acc, key) => {
    if (!property.get(key)) {
      return acc
    }

    if (key === 'uf') {
      return `${acc}-${property.get(key)}`
    }

    const lineBreak =
      useLineBreak && (key === 'complement' || (key === 'neighborhood' && !useComplement))

    return lineBreak ? `${acc}\n${property.get(key)}` : `${acc}, ${property.get(key)}`
  }, `${address}`)

  return fullAddress
}

export const generateUniqueKey = value => {
  let id = ''

  try {
    id = crypto.randomUUID()
  } catch (e) {
    // crypto.randomUUID() not supported
    id = value + Date.now()
  }

  return id
}

const formatMoney = number => {
  const value = number || 0
  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const displayCurrency = (value, defaultValue = 'R$ 0,00') => {
  return value ? `R$ ${formatMoney(value)}` : defaultValue
}

/**
 * @description Reorders a list moving an element of it to another position
 * @param {any[]} list An array of anything that will reordered
 * @param {number} startIndex The element's index that should be moved
 * @param {number} endIndex The index where the element should be moved
 */
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const validatePasswordStrength = password => {
  if (password.length < 8 || !password) {
    return false
  }

  let hasDigit = false
  let hasLowercase = false
  let hasSpecialChar = false
  const specialChars = '!@#$%^&*()_+-='

  for (let i = 0; i < password.length; i += 1) {
    const char = password[i]
    if (!hasDigit && !isNaN(char)) {
      hasDigit = true
    }
    if (!hasLowercase && char === char.toLowerCase() && isNaN(char)) {
      hasLowercase = true
    }
    if (!hasSpecialChar && specialChars.includes(char)) {
      hasSpecialChar = true
    }
  }

  return hasDigit && hasLowercase && hasSpecialChar
}
