import React from 'react'
import { Helmet } from 'react-helmet'

import { ROUTES } from '_utils/constants'

export const HEADER_TAGS = {
  [ROUTES.MANAGE_ORDERS]: {
    header: (
      <Helmet>
        <title>Refera</title>
        <meta
          name="description"
          content="Reinventamos a área de manutenções e reformas das imobiliárias, assim como ajudamos os prestadores de serviço a ganhar mais dinheiro."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
    ),
  },
  [ROUTES.SETUP_AGENCY_FORM]: {
    header: (
      <Helmet>
        <title>Formulário de abertura</title>
        <meta
          name="description"
          content="A plataforma de manutenções para imobiliárias, cuidamos do chamado de ponta a ponta."
        />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
    ),
  },
  [ROUTES.RECOVER_PASSWORD]: {
    header: (
      <Helmet>
        <title>Formulário de abertura</title>
        <meta
          name="description"
          content="A plataforma de manutenções para imobiliárias, cuidamos do chamado de ponta a ponta."
        />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
    ),
  },
  [ROUTES.GENERATE_CONTRACT]: {
    header: (
      <Helmet>
        <title>Formulário de Geração de Contrato</title>
        <meta
          name="description"
          content="Reinventamos a área de manutenções e reformas das imobiliárias, assim como ajudamos os prestadores de serviço a ganhar mais dinheiro."
        />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
    ),
  },
  MOVIDESK_CHAT_TRADESMAN: {
    header: (
      <Helmet>
        <script type="text/javascript">{`var mdChatClient="BFA509D28B814BD587B70AB4B57494EF";`}</script>
        <script src="https://chat.movidesk.com/Scripts/chat-widget.min.js" />
        <link rel="stylesheet" href="/movidesk.css" type="text/css" />
      </Helmet>
    ),
  },
  MOVIDESK_CHAT_INTERMEDIARY: {
    header: (
      <Helmet>
        <script type="text/javascript">{`var mdChatClient="60795D6802C747BF88ACF6E5FB179443";`}</script>
        <script src="https://chat.movidesk.com/Scripts/chat-widget.min.js" />
        <link rel="stylesheet" href="/movidesk.css" type="text/css" />
      </Helmet>
    ),
  },
}
